.status{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--ErrorHover);
    background-color: rgba(254, 236, 236, 1);
    gap: 8px;
    font-size: 12px;
    width: fit-content;
    .dot{
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: var(--ErrorHover);
    }
    &.active{
        color: var(--Success);
        background-color: rgba(213, 242, 159, 0.4);
        .dot{
            background-color: var(--Success);
        }
    }    
}