.wrap{
    width: 100%;
    height: 100vh;
    background-color: var(--White);
    display: flex;
    .left{
        align-self: stretch;
        flex: 1;
        display: flex;
        padding: 32px 64px;
        background-image: url('~/public/images/login.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-color: var(--Shadow);
    }
    .right{
        align-self: stretch;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .switcherWrap{
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .formWrap{
            display: flex;
            flex-direction: column;
            max-width: 460px;
            gap: 40px;
            .titles{
                display: flex;
                flex-direction: column;
                max-width: 100%;
                gap: 16px;
                .title{
                    font-size: 30px;
                    font-weight: 600;
                    width: 100%;
                    display: flex;
                    font-family: 'Unbounded';
                    white-space: pre-line
                }
                .sub{
                    font-size: 14px;
                    font-weight: 24px;
                    color: var(--n9);
                    width: 100%;
                    display: flex;
                }
            }
        }
    }
}
@media screen and (max-width: 767px){
    .wrap{
        background-color: var(--Shadow);
        .left{
            display: none;
        }
        .right{
            padding: 16px;
            .formWrap{
                background-color: var(--White);
                border-radius: 12px;
                padding: 40px;
                .titles{
                    .title{
                        font-size: 24px;
                    }
                    .sub{
                        font-size: 16px;
                    }
                }
            }

        }
    }
}