.wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 4px 8px;
    position: relative;
    cursor: pointer;
    background-color: var(--LightSweetMint);
    border: 1px solid var(--LightSweetMint);
    border-radius: 10px;
    min-width: 190px;
    .icon{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
    }
    &:hover,
    &.open{
        background-color: var(--n1);
        border: 1px solid var(--n2);
        .icon{
            transform: rotate(180deg);
        }

    }    
    .info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        flex: 1;
        .user{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex: 1;
            .name{
                color: var(--Shadow);
                font-size: 10px;
                font-weight: 600;
                text-transform: capitalize;
            }
            .company{
                color: var(--n9);
                font-size: 10px;
                text-transform: capitalize;
            }
        }
        .avatar{
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: var(--Shadow);
            color: var(--SweetMint);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
    .menu{
        position: absolute;
        left: 0;
        top: 50px;
        cursor: pointer;
        display: none;
        color: var(--Shadow);
        background-color: var(--White);
        border: 1px solid var(--n2);
        padding: 8px 0;
        border-radius: 10px;
        z-index: 1;
        width: 100%;
        text-align: left;
        gap: 8px;
        flex-direction: column;
        &.open{
            display: flex;
        }
        .name{
            color: var(--n9);
            font-size: 14px;
            padding: 8px 16px 0 16px;
            width: 100%;
            text-align: left;
        }
        .email{
            color: var(--n9);
            font-size: 12px;
            padding: 0px 16px 8px 16px;
            width: 100%;
            text-align: left;
        }
        .line{
            width: 100%;
            height: 1px;
            background-color:  var(--n2);
        }
        .company{
            color: var(--Shadow);
            font-size: 14px;
            padding: 8px 16px;
            width: 100%;
            text-align: left;
        }
        .logout{
            color: var(--ErrorHover);
            font-size: 14px;
            padding: 8px 16px 8px 42px;
            width: 100%;
            text-align: left;
            background-image: url('~/public/icons/log-out.svg');
            background-position: 16px center;
            background-repeat: no-repeat;
        }
    }
}
@media screen and (max-width: 767px){
    .wrap{
        order: 1;
    }
}