
.wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}
.flex {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
}
.h2{
    margin-bottom: 20px;
}
.divider{
    height: 20px;
    width: 2px;
    background-color: var(--n2);
}
.clear {
    height: 40px;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color:  var(--ErrorHover); /* Наприклад, червоний текст */
}
.btn {
    background-color: var(--SweetMint);
    border-radius: 12px;
    height: 40px;
    padding: 8px 12px;
    color: var(--Shadow);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        background-color: var(--BtnHover);
    }
}
.btnOutline{
    height: 40px;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid var(--n2);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: var(--Shadow);
    background-color: var(--White);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        border: 1px solid var(--SweetMint);
    }
}
.circle {
    background: var(--SweetMint);
    display: flex;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 24px;
    height: 24px;
}
.drawerContent {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.filtersList {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.drawerButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid var(--LightSweetMint);
    gap: 10px;
    .btnOutline{
        flex: 1;
    }
    .btn{
        flex: 1;
    }
}
.dialogButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    .btnOutline{
        flex: 1;
    }
    .btn{
        flex: 1;
    }
}