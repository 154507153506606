/* Загальний контейнер сторінки */
.settingsWrapper {
    padding: 24px;
    background-color: var(--BG); /* Злегка відрізняється від білого, як на скріні */
  }
  
  /* Заголовок сторінки */
  .pageTitle {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    color: var(--Shadow);
  }
  
  /* Кожен блок налаштувань */
  .section {
    background-color: var(--White);
    border: 1px solid var(--n2);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
  }
  
  /* Заголовок у кожному блоці */
  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
  }
  
  /* Блок з аватаром та полями */
  .profileInfo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    gap: 24px;
  }
  
  /* Блок для аватара */
  .avatarBlock {
    display: flex;
    align-items: center;
  }
  
  /* Коло-аватар (з ініціалами) */
  .avatarCircle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: var(--Shadow); 
    color: var(--SweetMint); 
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  /* Блок з полями (Ім’я, Прізвище, Email) */
  .profileFields {
    flex: 1; 
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
  
  /* Кожне поле (лейбл + інпут) */
  .field {
    display: flex;
    flex-direction: column;
    min-width: 220px; /* щоб поля не були надто вузькими */
  }
  
  /* Текст лейбла */
  .label {
    font-size: 14px;
    margin-bottom: 6px;
    color: var(--Shadow);
  }
  
  /* Стилі для інпутів та селектів */
  .input {
    padding: 8px 12px;
    font-size: 14px;
    color: var(--Shadow);
    border: 1px solid var(--n2);
    border-radius: 4px;
    outline: none;
  
    &:disabled {
      background-color: #f1f1f1;
      color: #999;
      cursor: not-allowed;
    }
  }
  
  /* Налаштування часу – звичайний контейнер для 2 інпутів */
  .timeSettings {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 16px; /* відступ від кнопки */
  }
  
  /* Кнопка */
  .button {
    padding: 8px 24px;
    font-size: 14px;
    color:var(--Shadow);
    background-color: var(--BG);
    border: solid 1px var(--n2);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  
    &:hover {
      background-color: var(--n1);
    }
  }
  
  /* Посилання для виходу */
  .exitLink {
    display: inline-block;
    font-size: 14px;
    color: #db1f1f;
    text-decoration: none;
    margin-top: 4px;
  
    &:hover {
      text-decoration: underline;
    }
  }
  

  /* Спільні стилі для модальних вікон */
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* напівпрозоре затемнення */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* щоб перекрити основний контент */
  }
  
  .modalContent {
    background-color: #fff;
    width: 400px;
    max-width: 90%;
    padding: 24px;
    border-radius: 8px;
  }
  
  /* Кнопки в модальному вікні */
  .modalButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  
    button {
      padding: 8px 16px;
      margin-left: 8px;
      background-color: #007aff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  
      &:first-of-type {
        background-color: #ccc;
        color: #333;
      }
    }
  }
  