.wrap{
    position: relative;
    min-width: 245px;
    width: 100%;
    .calendarWrap{
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border: 1px solid var(--n2);
        border-radius: 8px;
        background-color: var(--White);
        .dates{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            .item{
                display: flex;
                align-items: center;
                flex: 1;
                height: 40px;
                padding: 0 12px;
                border: 1px solid var(--n9);
                border-radius: 8px;
            }
        }
    }
}