.wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    .time{
        height: 44px;
        padding: 8px;
        gap: 4px;
        border-radius: 12px;
        border: 1px solid var(--n2);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: var(--Shadow);
        background-color: var(--White);
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            min-width: 106px;
        }
    }  
}
@media screen and (max-width: 767px){
    .wrap{
        flex-direction: column;
        order: 2;
    }
}