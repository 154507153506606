.header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
        font-size: 22px;
        font-weight: 500;
        font-family: 'Unbounded';
    }
    .filter{
        display: flex;
        gap: 16px;
    }
    .download{
        position: relative;
        width: 150px;
        height: 40px;
        border: 1px solid var(--n1);
        border-radius: 8px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        white-space: nowrap;  
        background-color: var(--White);
        gap: 8px;
        cursor: pointer;
        &:hover{
            border: 1px solid var(--SweetMint);
        }
    }
}
.tabs{
    width: 100%;
    display: flex;
    align-items: center;
    .tab{
        display: flex;
        align-items: flex-start;
        border-bottom: 2px solid var(--n2);
        padding: 0 32px 12px 32px;
        color: var(--n7);
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
        &.active{
            color: var(--Shadow);
            border-bottom: 2px solid var(--Shadow);
        }
    }
}