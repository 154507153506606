.wrap{
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .select{
        display: flex;
        align-items: center;
        gap: 8px;
        .input{
            width: 50px;
            height: 26px;
            border: 1px solid var(--n3);
            border-radius: 8px;
            padding: 0 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            white-space: nowrap;
            background-color: var(--White);
            text-align: center;
            outline: none;
        }
    }

    .pagination{
        display: flex;
        gap: 4px;
        .item{
            min-width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: var(--n10);
            border-radius: 24px;
            padding: 0 4px;
            cursor: pointer;
            &:hover{
                color: var(--Shadow);
                background-color: var(--BtnHover);
            }
            &.active{
                color: var(--Shadow);
                background-color: var(--SweetMint);
            }
        }
    }
}

