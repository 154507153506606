.wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    padding: 12px;
    gap: 8px;
    border: 1px solid var(--n3);
    background-color: var(--White);
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    width: 125px;
    .icon{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: var(--transition);
    }
    .list{
        display: none;
        position: absolute;
        left: 0;
        top: 50px;
        color: var(--Shadow);
        background-color: var(--White);
        border: 1px solid var(--n2);
        padding: 8px 0;
        border-radius: 10px;
        z-index: 1;
        width: 100%;
        text-align: left;
        gap: 8px;
        flex-direction: column;
        .item{
            color: var(--Shadow);
            font-size: 14px;
            padding: 8px 16px 0 16px;
            width: 100%;
            text-align: left;
        }
    }
    &:hover{
        background-color: var(--n1);
        border: 1px solid var(--n2);
        .icon{
            transform: rotate(180deg);
        }
    }
    &.open{
        background-color: var(--n1);
        border: 1px solid var(--n2);
        .icon{
            transform: rotate(180deg);
        }
        .list{
            display: flex;
        }
    }
}