.wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    .item{
        height: 44px;
        padding: 8px 12px;
        gap: 4px;
        border-radius: 12px;
        border: 1px solid var(--n2);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: var(--Shadow);
        background-color: var(--White);
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            color: var(--Raise)
        }
        &.dark{
            background-color: var(--Shadow);
            border: 1px solid var(--Shadow);
            color: var(--White);
            span{
                color: var(--Raise)
            }
        }
    }  
}
@media screen and (max-width: 767px){
    .wrap{
        flex-direction: column;
        order: 2;
    }
}