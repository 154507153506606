.statCalls{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .statCallsItem{
        display: flex;
        flex-direction: column;
        height: 105px;
        flex: 1;
        border: 1px solid var(--n5);
        border-radius: 10px;
        padding: 12px 16px;
        gap: 8px;
        background-color: var(--White);
        .top{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name{
                display: flex;
                flex: 1;
                font-size: 18px;
                color: var(--Shadow);
                font-weight: 600;
            }
            .info{
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .tooltip{
                    position: absolute;
                    right: 0;
                    bottom: -48px;
                    background-color: var(--White);
                    border-radius: 8px;
                    border: 1px solid var(--n2);
                    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.07);
                    color: var(--Shadow);
                    font-size: 12px;
                    padding: 12px;
                    z-index: 1;
                    white-space: nowrap;
                    display: none;
                }
                &:hover{
                    .tooltip{
                        display: block;
                    }
                }
            }
        }
        .data{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            .value{
                font-size: 40px;
                color: var(--Shadow);
                font-weight: 500;
            }
            .dynamic{
                display: flex;
                align-items: center;
                gap: 2px;
                font-size: 12px;

                .lm{
                    color: var(--n9);
                }
                &.up{
                    color: var(--Raise);
                }
                &.down{
                    color: var(--Downgrade);
                }
            }
        }
    }
}
.statRegion{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .statRegionItem{
        display: flex;
        flex-direction: column;
        height: 365px;
        flex: 1;
        border: 1px solid var(--n5);
        border-radius: 10px;
        padding: 24px;
        gap: 16px;
        background-color: var(--White);
        .top{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name, .info{
                display: flex;
                flex: 1;
                font-size: 16px;
                color: var(--Shadow);
                font-weight: 600;
            }
            .info{
                justify-content: flex-end;
            }
        }
        .data{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            .chart{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .total{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    color: var(--n7);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    .value{
                        font-size: 25px;
                        color: var(--Shadow);
                        font-weight: 600;
                    }
                }
            }
            .list{
                flex: 1;
                display: flex;
                flex-direction: column;
                border-top: 1px solid var(--n5);
                padding: 12px;
                overflow-y: scroll;
                max-height: 300px;
                .scrol{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 225px;
                    overflow: auto;
                    gap: 8px;
                }
                .listItem{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 32px;
                    gap: 12px;
                    padding: 0 8px;
                    .dot{
                        width: 8px;
                        height: 8px;
                        border-radius: 8px;
                    }
                    .name{
                        font-size: 14px;
                        color: var(--Shadow);
                        flex: 1;
                        display: flex;
                        align-items: center;
                        &.header{
                            color: var(--n5);
                        }
                    }
                    .value{
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--Shadow);
                        display: flex;
                        justify-content: flex-end;
                        &.header{
                            color: var(--n5);
                        }
                    }
                }
                
            }
        }
    }
}