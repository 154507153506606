.filters {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.filterItem {
    display: flex;
    flex-direction: column;
}

.filterItem label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.filterItem select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.applyButton {
    padding: 8px 16px;
    background-color: #28a745;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.applyButton:hover {
    background-color: #218838;
}

.toggleButtons {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
    margin-left: 60px;
}

.toggleLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #333;

    input {
        cursor: pointer;
    }
}

.toggleLabel:nth-of-type(1) {
    color: #82ca9d; /* Колір для Загального профіту */
}

.toggleLabel:nth-of-type(2) {
    color: #8884d8; /* Колір для Профіт по дзвінкам */
}

.toggleLabel:nth-of-type(3) {
    color: #ffc658; /* Колір для Профіт по номерам */
}

.toggleLabel:nth-of-type(4) {
    color: #ff7300; /* Колір для Профіт по АТС */
}

.toggleLabel:nth-of-type(5) {
    color: #007300; /* Колір для Профіт по СМС */
}
