:root {
    --BG: rgba(255, 255, 249, 1);
    --White: #FFFFFF;
    --Shadow: #272727;
    --SweetMint: #D5F29F;
    --LightSweetMint: #EFF6D5;

    --Success: #00925D;
    --Error: #F71310;
    --ErrorHover: #D10B08;
    --Warning: #DE630F;

    --Raise: #7BC74D;
    --Downgrade: #FF4C4C;

    --BtnHover: rgba(146, 177, 28, 1);


    --n1: #E8E8E8;
    --n2: #DCDCDC;
    --n3: #D1D1D1;
    --n4: #C5C5C5;
    --n5: #B9B9B9;
    --n6: #ADADAD;
    --n7: #A1A1A1;
    --n8: #959595;
    --n9: #898989;

    --transition: all 0.3s ease;
}

html,
body {
    width: 100vw;
    min-height: 100vh;
}

body {
    color: var(--Shadow);
    background: var(--BG);
    font-family: 'Inter';
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}