.wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px 32px;
    align-items: center;
    position: relative;

    .header{
        width: 100%;
        background-color: var(--Shadow);
        height: 68px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
    }
    .progress{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .back{
            height: 40px;
            width: 100%;
            max-width: 195px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 12px;
            color: var(--Shadow);
            cursor: pointer;
            gap: 8px;
        }

        .indicator{
            width: 100%;
            max-width: 670px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            .line{
                display: flex;
                flex: 1;
                height: 8px;
                border-radius: 4px;
                background-color: var(--n1);
                .used{
                    height: 8px;
                    border-radius: 4px;
                    background-color: var(--SweetMint);
                }
            }
        }   
        .empty{
            height: 40px;
            width: 100%;
            max-width: 195px;
            display: flex;
            align-items: center;
            justify-content: center;
        }     
    }

    .content{
        width: 100%;
        max-width: 745px;
        display: flex;
        align-items: center;
        gap: 32px;
        flex-direction: column;
        .titles{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
            flex-direction: column;
            .title{
                font-size: 32px;
                text-align: center;
                font-family: 'Unbounded';
            }
            .subtitle{
                font-size: 16px;
                text-align: center;
            }            
        }

        .roles{
            display: flex;
            align-items: center;
            gap: 24px;
            justify-content: space-between;
            width: 100%;
            .role{
                display: flex;
                flex: 1;
                height: 360px;
                background-position: center center;
                background-repeat: no-repeat;
                border: 2px solid var(--n1);
                border-radius: 12px;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 48px;
                gap: 12px;
                cursor: pointer;
                .dot{
                    width: 20px;
                    height: 20px;
                    border: 1px solid var(--n1);
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg{
                        opacity: 0;
                    }
                }
                &:hover{
                    border: 2px solid var(--SweetMint);
                }
                &.active{
                    border: 2px solid var(--SweetMint);
                    .dot{
                        border: 1px solid var(--Shadow);
                        background-color: var(--Shadow);
                        svg{
                            opacity: 1;
                        }
                    }
                }                
                &.role1{
                    background-image: url('~/public/images/role1.jpg');
                    &.active{
                        background-image: url('~/public/images/role1_1.jpg');
                    }  
                }
                &.role2{
                    background-image: url('~/public/images/role2.jpg');
                    &.active{
                        background-image: url('~/public/images/role2_1.jpg');
                    } 
                }

            }
        }
        .form{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .upload{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            flex-direction: column;
            .example{
                width: 100%;
                max-width: 432px;
                display: flex;
                align-items: center;
                gap: 16px;
                flex-direction: column;
                padding: 12px;
                border: 1px solid var(--SweetMint);
                border-radius: 12px;
                .images{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .image{
                        display: flex;
                        align-items: center;
                        flex: 1;
                        justify-content: center;
                        position: relative;
                        svg{
                            position: absolute;
                            right: -4px;
                            bottom: -4px;
                        }
                        .blur{
                            filter: blur(2px);
                            opacity: 0.8;
                        }
                    }
                }
                .list{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 12px;
                    .title{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .item{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        padding-left: 24px;
                        background-image: url('~/public/icons/check.svg');
                        background-position: left center;
                        background-repeat: no-repeat;
                    }
                }
            }
            .files{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                flex-direction: column;
                padding: 12px;
                border: 1px solid var(--n6);
                border-radius: 12px;
                cursor: pointer;
                &.error{
                    border: 1px solid var(--Error); 
                }
                &:hover,
                &.hover{
                    border: 1px solid var(--SweetMint); 
                }
                .title{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    font-size: 14px;
                    justify-content: center;
                }
                .item{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    font-size: 12px;
                    color: var(--n6);
                    justify-content: center;
                }
                
            }
            .fileslist{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .item{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;
                    padding: 10px 16px;
                    border: 1px solid var(--n6);
                    border-radius: 8px;
                    .left{
                        display: flex;
                        flex-direction: column;
                        .name{
                            font-size: 14px;
                            color: var(--Shadow)
                        }
                        .size{
                            font-size: 14px;
                            color: var(--n6)
                        }
                    }
                    .right{
                        display: flex;
                        gap: 12px;
                        align-items: center;
                        justify-content: flex-end;
                        .del{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .btn{
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: var(--SweetMint);
            border-radius: 8px;
            padding: 10px 12px;
            color: var(--Shadow);
            cursor: pointer;
            &:hover{
                background-color: var(--BtnHover);
            }
        }
        &.step1{
            width: 745px;
            .btn{
                width: 360px;
            }
        }
        &.step2{
            width: 360px;
        }
        &.step3{
            width: 360px;
        }
        &.step4{
            width: 430px;
        }
        &.step5{
            width: 430px;
        }
        &.step6{
            width: 430px;
        }
        &.step7{
            width: 460px;
        }
    }
}
