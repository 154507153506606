form{
    width: 100%;
    display: flex;
    gap: 24px;
    flex-direction: column;
}
.field{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    .eye{
        position: absolute;
        right: 12px;
        top: 30px;
        cursor: pointer;
    }
}
.label{
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--Shadow);
}
.link{
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--Shadow);
    justify-content: flex-end;
    cursor: pointer;
}
.input{
    width: 100%;
    border: 1px solid var(--n2);
    border-radius: 4px;
    height: 36px;
    padding: 0 12px;
    font-size: 14px;
    &.error{
        border: 1px solid var(--Error);
    }
}
.checkboxField{
    width: 100%;
    display: flex;
    gap: 12px;
    position: relative;
    align-items: center;
    .checkbox{
        display: flex;
        width: 25px;
        height: 25px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid var(--n1);
        &.checked{
            background-color: var(--Shadow);
            border: 1px solid var(--Shadow);
        }
        &.error{
            border: 1px solid var(--Error);
        }
    }
    .label{
        width: 90%;
        display: inline;
        align-items: center;
        font-size: 14px;
        color: var(--Shadow);
    }
}
.btns{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
}
.btnsCol{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}
.button{
    width: 100%;
    background-color: var(--SweetMint);
    border-radius: 12px;
    height: 40px;
    padding: 0 12px;
    color: var(--Shadow);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        background-color: var(--BtnHover);
    }
    &.short{
        width: auto;
    }
    &.warning{
        background-color: var(--Warning);
        color: var(--White);
    }
    &.error{
        background-color: var(--Error);
        color: var(--White);
    }
}
.btnOutline{
    height: 32px;
    width: 32px;
    border-radius: 6px;
    border: 1px solid var(--n3);
    background-color: var(--White);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        border: 1px solid var(--SweetMint);
    }
}
.error{
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--Error);
    gap: 4px;
    font-size: 12px;    
    .errorIcon{
        path{
            fill: var(--Error);
        }
    }
}
.title{
    font-size: 22px;
    font-weight: 500;
    font-family: 'Unbounded';
}
.close{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-image: url('~/public/icons/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
}
.search{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 270px;
    gap: 8px;
    border-radius: 12px;
    height: 40px;
    border: 1px solid var(--n3);
    input{
        width: 70%;
        border: none;
        outline: none;
    }
    div{
        width: 30%;

    }
}

@media screen and (max-width: 767px){
    .link{
        text-decoration: underline;
    }
}