.wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--LightSweetMint);
    padding: 12px 16px;
    border-radius: 8px;
    .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
    .topMobile{
        display: none;
    }
}
.wrapMobile{
    display: none;
}
@media screen and (max-width: 767px){
    .wrapMobile{
        display: flex;
        height: 64px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: var(--LightSweetMint);
        padding: 12px;
        border-radius: 8px;
        .logo{
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                height: auto;
            }
        }
        .burger{
            cursor: pointer;
            width: 44px;
            height: 44px;
            border-radius: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--SweetMint);
        }
    }
    .wrap{
        display: none;
        &.open{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: fixed;
            left: 16px;
            top: 16px;
            bottom: 16px;
            background-color: var(--SweetMint);
            z-index: 10;
            width: calc(100% - 32px);
            padding: 16px;
        }
        .topMobile{
            display: flex;
            height: 44px;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            .logo{
                width: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .close{
                cursor: pointer;
                width: 44px;
                height: 44px;
                border-radius: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .item{
            flex-direction: column;
            order: 1;
        }
    }
}